import React, { FC } from 'react';

interface WaitingForRoundProps {
    onRoundStart: () => void;
}

const WaitingForRound: FC<WaitingForRoundProps> = ({ onRoundStart }) => {
    const handleStartClick = () => {
        onRoundStart();
    };

    return (
        <div>
            <h1>Waiting for the next round to start...</h1>
            <button onClick={handleStartClick}>Start Round</button>
        </div>
    );
};

export default WaitingForRound;
