import React, { FC, PropsWithChildren, createContext, useContext, useMemo } from "react";
import { GameRound } from "../Types/core/GameData";

export type CurrentRoundProviderType = {
    round: GameRound;
}

export const CurrentRoundContext = createContext<CurrentRoundProviderType>({
    round: {} as GameRound
})

export const useCurrentRound = ()=>useContext(CurrentRoundContext);

export type CurrentRoundProviderProps = {
    round: GameRound;
} & PropsWithChildren;

export const CurrentRoundProvider:FC<CurrentRoundProviderProps> = ({round, children}) => {

    const value = useMemo(()=>({
        round
    }), [round])
    return (
        <CurrentRoundContext.Provider value={value}>
            {children}
        </CurrentRoundContext.Provider>
    );
};