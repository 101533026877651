import React, { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';

type ApiProviderProps = PropsWithChildren;

interface IApiContext {
    fetch: <T>(url: string, options?: RequestInit) => Promise<T>;
}

export const ApiContext = createContext<IApiContext>({fetch: async () => {}} as IApiContext);
export const useApi = () => useContext(ApiContext);

const getBaseUrl = (): string => {
    // Check if the URL contains "localhost"
    if (window.location.href.includes('localhost')) {
        return 'http://127.0.0.1:8788';
    }
    else if (process.env.REACT_APP_API_URL) {
        return process.env.REACT_APP_API_URL;
    }
    else {
        return `${window.location.protocol}//${window.location.hostname}`;
    }
};
 
const baseUrl = getBaseUrl();

export const ApiProvider: FC<ApiProviderProps> = ({ children })=>
{
    const value = useMemo(() => ({
        fetch: async (url: string, options?: RequestInit) => {
            const response = await fetch(`${baseUrl}/${url}`, options);
            const data = await response.json();
            return data;
        }
    } as IApiContext), []);
    return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};