import React, { useMemo } from 'react';
import { Player } from '../../Types/core/GameData';
import { useCurrentRound } from '../../Providers/CurrentRoundProvider';
import { ReceivedVotesCard } from '../ReceivedVotesCard';
import styled from 'styled-components';

type RoundFinishedProps = {
    players: Player[];
    onNextRoundStart: () => void;
}

const List = styled.ul`
list-style: none;
padding: 0;
li {
    margin-top: 16px;
    margin-bottom: 16px;
}
`;

export const RoundFinished: React.FC<RoundFinishedProps> = ({ players,onNextRoundStart }) => {
    const {round} = useCurrentRound();
    const playerAnswerVotes = useMemo(()=> round.answers.map((answer)=>{
        // const answer = round.answers.find((answer)=>answer.playerId === player.id);
        const player = players.find((player)=>player.id === answer?.playerId);
        const votes = round.answerVotes.filter((vote)=>vote.answerId === answer?.id);
        const votePlayers = votes.map((vote)=>players.find((player)=>player.id === vote.playerId)?.name);
        return {
            player: player,
            answer: answer,
            votes: votePlayers
        }
    }), [players, round.answers, round.answerVotes]);
    const correctAnswer = playerAnswerVotes.find(pav => pav.answer?.isCorrect);
    const handleStartClick = () => {
        onNextRoundStart();
    };
    return (
        <div>
            <h2>Round Finished</h2>
            <h3>Correct Answer</h3>
            {correctAnswer && <ReceivedVotesCard player={undefined} votes={correctAnswer.votes ?? []} answer={correctAnswer.answer} question={round.question} />}
            <h3>Player Answers</h3>
            <List>
                {playerAnswerVotes.filter(pav => !!pav.player).map((pav) => (
                    <li key={pav.player?.id ?? 'correct'}>
                        <ReceivedVotesCard player={pav.player} votes={pav.votes} answer={pav.answer} question={round.question} />
                    </li>
                ))}
                </List>
            
            <h3>Player Points</h3>
            <ul>
                {players.map((player) => (<li key={player.id}>
                    <b>{player.name}:</b> {player.points ?? 0}
                </li>))}
            </ul>
            <button onClick={handleStartClick}>Start Next Round</button>
        </div>
    );
};
