
import React, { useState } from 'react';


type NewGameProps = {
    onStart: (options: GameOptions) => void;
    onCancel: () => void;
}

export type GameOptions = {
    numberOfRounds: number;
}

export const NewGame: React.FC<NewGameProps> = ({ onStart, onCancel }) => {
    const [numberOfRounds, setNumberOfRounds] = useState<string>('5');

    const handleStart = () => {
        const options: GameOptions = {
            numberOfRounds: parseInt(numberOfRounds)
        };
        onStart(options);
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <div>
            <label>
                Number of Rounds:
                <input type="text" value={numberOfRounds} onChange={(e) => setNumberOfRounds(e.target.value)} />
            </label>
            <button onClick={handleStart}>
                Start New Game
            </button>
            <button onClick={handleCancel}>Cancel</button>
        </div>
    );
};

export default NewGame;
