
import WaitingForRound from './WaitingForRound';
import Round from '../Round/Round';
import GameFinished from './GameFinished';
import { useCurrentGame } from '../../Providers/CurrentGameProvider';
import { CurrentRoundProvider } from '../../Providers/CurrentRoundProvider';
import React, { FC } from 'react';


const Game: FC = () => {
    const {gameData} = useCurrentGame();
    return (
        <div>
            {gameData.state === 'WAITING_FOR_ROUND' && <WaitingForRound onRoundStart={() => {}} />}
            {gameData.state === 'ROUND_IN_PROGRESS' && gameData.currentRound && (<CurrentRoundProvider round={gameData.currentRound}>
                <Round players={gameData.players} />
            </CurrentRoundProvider>)}
            {gameData.state === 'FINISHED' && <GameFinished/>}
        </div>
    );
};

export default Game;
