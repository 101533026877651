import React, { FC, PropsWithChildren, createContext, useContext, useMemo } from "react";
import { GameData, Player } from "../Types/core/GameData";

export type CurrentGameProviderType = {
    gameData: GameData;
    player: Player;
}

export const CurrentGameContext = createContext<CurrentGameProviderType>({
    gameData: {} as GameData,
    player: {} as Player
})

export const useCurrentGame = () => useContext(CurrentGameContext);

export type CurrentGameProviderProps = {
    game: GameData;
    playerId: string;
} & PropsWithChildren;

export const CurrentGameProvider: FC<CurrentGameProviderProps> = ({ game, playerId, children }) => {
    const value = useMemo(() => ({
        gameData: game,
        player: game.players.find(p => p.id === playerId)!
    }), [game, playerId])
    return (
        <CurrentGameContext.Provider value={value}>
            {children}
        </CurrentGameContext.Provider>
    );
};