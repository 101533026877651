import React from 'react';
import { Player } from '../../Types/core/GameData';
import { WaitingForAnswers } from './WaitingForAnswers';
import { WaitingForVotes } from './WaitingForVotes';
import { RoundFinished } from './RoundFinished';
import { useCurrentRound } from '../../Providers/CurrentRoundProvider';
import { useCurrentGame } from '../../Providers/CurrentGameProvider';
import { useApi } from '../../Providers/ApiProvider';


type RoundProps = {
    players: Player[]
}

const RoundHandler: React.FC<RoundProps> = ({ players }) => {
    const { gameData, player } = useCurrentGame();
    const {round} = useCurrentRound();
    const { roundState } = round;

    const {fetch} = useApi()

    const handleAnswerSubmit = (answer: string) => {
        console.log('Answer submitted: ', answer);
        fetch(`games/${gameData.id}/answer`, {
            method: 'POST',
            body: JSON.stringify({
                playerId: player.id,
                answer: answer
            })
        });
    }

    const handleVoteSubmit = (answerId: string) => {
        console.log('Vote submitted: ', answerId);
        fetch(`games/${gameData.id}/vote`, {
            method: 'POST',
            body: JSON.stringify({
                playerId: player.id,
                answerId: answerId
            })
        });
    }

    const handleNextRoundStart = ()=>{
        fetch(`games/${gameData.id}/nextRound`, {
            method: 'POST'
        });
    }

    const renderRoundComponent = () => {
        switch (roundState) {
            case 'WAITING_FOR_ANSWERS':
                return <WaitingForAnswers onAnswerSubmit={handleAnswerSubmit} />;
            case 'WAITING_FOR_VOTES':
                return <WaitingForVotes onVoteSubmit={handleVoteSubmit} />;
            case 'FINISHED':
                return <RoundFinished players={players} onNextRoundStart={handleNextRoundStart} />;
            default:
                return null;
        }
    };

    return (
        <div>
            {renderRoundComponent()}
        </div>
    );
};

export default RoundHandler;
