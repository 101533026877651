import React, { FC } from 'react';
import { useCurrentRound } from '../../Providers/CurrentRoundProvider';
import { useCurrentGame } from '../../Providers/CurrentGameProvider';
import { Card } from '../Card';
import styled from 'styled-components';
type WaitingForVotesProps = {
    onVoteSubmit(answerId: string): void;
}

const VoteButton = styled.button`
display: inline-block;
height: 225px;
vertical-align: top;
`;

const List = styled.ul`
list-style: none;
padding: 0;
li {
    margin-top: 16px;
    margin-bottom: 16px;
}
`;


export const WaitingForVotes: FC<WaitingForVotesProps> = ({onVoteSubmit}) => {
    const {round} = useCurrentRound();
    const{player, gameData} = useCurrentGame();
    const handleVote = (answerId: string) => {
        console.log('Vote submitted: ', answerId);
        onVoteSubmit(answerId);
    }
    const playerVotedAnswer = round.answers.find(a => a.id === round.answerVotes.find((vote)=>vote.playerId === player.id)?.answerId);
    const waitingForPlayers = gameData.players.filter((player)=>!round.answerVotes.find((vote)=>vote.playerId === player.id));
    const randomizedAnswers = round.answers.sort(()=>Math.random() - 0.5);
    return (
        <div>
            <h2>Waiting for votes</h2>
            {playerVotedAnswer ? (<><div>You voted for</div>
            <Card answer={playerVotedAnswer.text} question={round.question.text} proverb={round.question.proverb} readOnly={true} />
            <div>Waiting for votes from:</div>
                <ul>
                    {waitingForPlayers.map((player)=>(
                        <li key={player.id}>{player.name}</li>
                    ))}
                </ul>
            </>) : (
                <>
            
            <List>
                {randomizedAnswers.map((answer) => (
                    <li key={answer.id}>
                        <div style={{display: 'inline-block'}}>
                        <Card answer={answer.text} question={round.question.text} proverb={round.question.proverb} readOnly={true} />
                        </div>
                        <VoteButton onClick={() => handleVote(answer.id)}>Vote <br />{answer.playerId === player.id ? '(Your answer)' : ''}</VoteButton>
                    </li>
                ))}
            </List>
            </>
            )}
        </div>
    );
};
