import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
type CardProps = {
    proverb: string;
    question: string;
    answer: string;
    onChange?: (text: string) => void;
    readOnly: boolean;
    by?: string;
}

const CardContainer = styled.div`
    background-color: #333;
    width: 375px;
    height: 225px;
    position: relative;
    box-sizing: border-box;
    padding-left: 50px;
    padding-top: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
`;

const CardTitle = styled.span`
    transform: rotate(-90deg);
    color: white;
    position: absolute;
    left: -75px;
    bottom:100px;
    font-size: 20px
`;

const CardContent = styled.div`
    background-color: #FDDBAB;
    height: 100%;
    position: relative;
`;
const Prompt = styled.div`
    
`;
const Proverb = styled.div`
font-style: italic;
`;
const Answer = styled.textarea`
width: calc(100% - 16px);
resize: none;
padding: 8px;
border: none;
background-color: rgba(255,255,255,0.4);
height: calc(100% - 58px);
font-family: 'Arial';
`;

const Watermark = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/images/card-background.png');
    background-size: cover;
    opacity: 0.15;
`;

const CardContentContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const Card: React.FC<CardProps> = ({ proverb, question: prompt, answer, onChange, readOnly = false, by }) => {
    const [proverbElement, setProverbElement] = useState<HTMLElement | null>(null);
    const [promptElement, setPromptElement] = useState<HTMLElement | null>(null);
    const [answerHeight, setAnswerHeight] = useState<string>('0px');
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange?.(event.target.value);
    };
    useEffect(() => {
        if (proverbElement?.clientHeight && promptElement?.clientHeight) {
            setAnswerHeight(`calc(100% - ${proverbElement.clientHeight + promptElement.clientHeight + 16}px)`);
        }
    }, [proverbElement, promptElement])
    return (
        <CardContainer>
            <CardTitle>Waise and Otherwaise</CardTitle>
            <CardContent>
                <Watermark></Watermark>
                <CardContentContainer>
                    <Proverb ref={(el) => setProverbElement(el)}>{proverb}</Proverb>
                    <Prompt ref={(el) => setPromptElement(el)}>{prompt}</Prompt>
                    <Answer autoFocus placeholder='Answer here' onChange={handleChange} readOnly={readOnly} style={{ height: answerHeight }}>{answer}</Answer>
                    {by && (<div style={{ position: 'absolute', bottom: '2px', right: '4px', fontStyle: 'italic' }}>&ndash; {by}</div>)}
                </CardContentContainer>
            </CardContent>

        </CardContainer>
    );
};

