import React from 'react';
import { Answer, Player, Question } from '../Types/core/GameData';
import { Card } from './Card';
import styled from 'styled-components';

export type ReceivedVotesCardProps = {
    player: Player | undefined;
    answer: Answer;
    votes: (string | undefined)[];
    question: Question;
}

const Wrapper = styled.div`
display: inline-block;
vertical-align: top;
`;
const List = styled.ul`
list-style: none;
padding: 0;
padding-left: 16px;
li {
    margin-top: 8px;
    margin-bottom: 8px;
}
`;

export const ReceivedVotesCard: React.FC<ReceivedVotesCardProps> = ({ player, answer, votes, question }) => {
    
    return (<>
    <Wrapper>
        <Card answer={answer.text} proverb={question.proverb} question={question.text} readOnly by={player?.name ?? 'AI'} />
    </Wrapper>
    <Wrapper>
        {votes?.length === 0 ? <div>Received no votes</div> : (
        <>
        Received votes from:
    <List>
        {votes.map((vote, index) => (
            <li key={index}>{vote}</li>
        ))}
    </List>
    </>
        )}
    </Wrapper>
    </>);
};

