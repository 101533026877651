import React, { FC } from 'react';
import { GameData } from '../Types/core/GameData';
import { Link } from 'react-router-dom';

export type LobbyProps = {
    gameData: GameData;
    userName: string;
    onStartGame: () => void;
};
const Lobby: FC<LobbyProps> = ({gameData, onStartGame}) => {
    

    return (
        <div>
            <h1>Lobby</h1>
            <ul>
                {gameData.players.map((player, index) => (
                    <li key={index}>{player.name}</li>
                ))}
            </ul>
            <div>
                <h1>Waiting for players</h1>
                <h2>Share this link with your friends: <Link target="_blank" to={`/game/${gameData.id}`}>{window.location.href}</Link></h2>
            </div>
            <div>
                <button onClick={onStartGame}>Start Game</button>
            </div>
        </div>
    );
};

export default Lobby;
