
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GameData, Player } from '../Types/core/GameData';
import { useApi } from '../Providers/ApiProvider';
import Lobby from '../Components/Lobby';
import Game from '../Components/Game/Game';
import { CurrentGameProvider } from '../Providers/CurrentGameProvider';
import { GroupDataMessage, WebPubSubClient } from '@azure/web-pubsub-client';

type SocketMessage = { action: string; data: GameData }


export const GamePage: React.FC = () => {
    const { gameId } = useParams<{ gameId: string }>();
    const [gameData, setGameData] = useState<GameData | null>(null);
    const [tempUserName, setTempUserName] = useState<string>('');
    const [player, setPlayer] = useState<Player | null>(
        localStorage.getItem(`${gameId}_player`) ?
            (JSON.parse(localStorage.getItem(`${gameId}_player`)!) as Player) :
            null);
    const { fetch } = useApi();

    const processMessage = useCallback((message: GroupDataMessage) => {
        if (!message) {
            return undefined;
        }
        if (message.dataType === 'text') {
            return JSON.parse(message.data as string) as SocketMessage;
        }
        if (message.dataType === 'json') {
            return message.data as SocketMessage;
        }
        return undefined;
    }, [])

    useEffect(() => {
        (async () => {
            if (gameId) {
                const data = await fetch<GameData>(`games/${gameId}`, {
                    method: 'GET',
                });

                setGameData(data);
            }
        })();
    }, [fetch, gameId]);

    useEffect(() => {
        (async () => {
            if (player) {
                const client = new WebPubSubClient(player.websocketUrl);
                await client.start();
                client.on('disconnected', () => {
                    console.log('Connection closed');
                });
                client.on('connected', () => {
                    console.log('Connected');
                });
                client.on('group-message', (message) => {
                    const processedMessage = processMessage(message.message);
                    if (processedMessage && processedMessage.action === 'SET_GAME_DATA') {
                        setGameData(processedMessage.data);
                    }
                });
                if (gameId) {
                    const response = await client.joinGroup(gameId);
                    console.log(response);
                    await client.sendToGroup(gameId, `${player.name} join the group`, 'text');
                }
            }
        })();
    }, [player, gameId, processMessage])

    const handleSetUserName = () => {
        (async () => {
            if (tempUserName) {

                const player = await fetch<Player>(`games/${gameId}/join`, {
                    method: 'POST',
                    body: JSON.stringify({
                        name: tempUserName,
                    }),
                });
                setPlayer(player);

                localStorage.setItem(`${gameId}_player`, JSON.stringify(player));
            }
        })();

    }

    const handleStartGame = () => {
        if (gameData) {
            fetch(`games/${gameData.id}/start`, {
                method: 'POST',
            });
        }
    }

    return (
        <div>
            {!player && (
                <div>
                    <h1>Enter your username</h1>
                    <input type="text" onChange={(e) => setTempUserName(e.target.value)} />
                    <button onClick={handleSetUserName}>Join Game</button>
                </div>
            )}
            {player && gameData && (
                gameData.state === 'WAITING_FOR_START' ? (
                    <Lobby gameData={gameData} userName={player.name} onStartGame={() => handleStartGame()} />
                ) : (
                    <CurrentGameProvider game={gameData} playerId={player.id}>
                        <Game />
                    </CurrentGameProvider>
                )
            )}
        </div>
    );
};