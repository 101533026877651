import React, { FC, useState } from 'react';
import { useCurrentRound } from '../../Providers/CurrentRoundProvider';
import { useCurrentGame } from '../../Providers/CurrentGameProvider';
import { Card } from '../Card';


export type WaitingForAnswersProps = {
    onAnswerSubmit: (answer: string) => void;
};

export const WaitingForAnswers: FC<WaitingForAnswersProps> = ({onAnswerSubmit}) => {
    const {round} = useCurrentRound();
    const {player, gameData} = useCurrentGame();
    const [answer, setAnswer] = useState('');
    const playerAnswer = round.answers.find((answer)=>answer.playerId === player.id);
    const waitingForPlayers = gameData.players.filter((player)=>!round.answers.find((answer)=>answer.playerId === player.id));

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        // Handle the submitted answer here
        onAnswerSubmit(answer);
    };

    const handleChange = (text: string) => {
        setAnswer(text);
    };

    return (
        <div>
            {playerAnswer ? (
                <>
                <Card answer={answer} question={round.question.text} proverb={round.question.proverb} readOnly={true} />
                <div>Waiting for answers from:</div>
                <ul>
                    {waitingForPlayers.map((player)=>(
                        <li key={player.id}>{player.name}</li>
                    ))}
                </ul>
            </>) : (<form onSubmit={handleSubmit}>
                <Card answer={answer} question={round.question.text} proverb={round.question.proverb} onChange={handleChange} readOnly={false} />
                <button type="submit">Submit Answer</button>
            </form>)}
        </div>
    );
};
