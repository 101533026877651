import React from 'react';
import { useCurrentGame } from '../../Providers/CurrentGameProvider';
import { Link } from 'react-router-dom';


const GameFinished: React.FC = () => {
    const {gameData} = useCurrentGame();
    const {players} = gameData;
    const highestScore = Math.max(...players.map(player => player.points));
    const winners = players.filter(player => player.points === highestScore);
    const sortedPlayers = players.sort((a, b) => a.points - b.points);
    return (
        <div>
            <h1>Game Finished!</h1>
            <h2>Scores:</h2>
            <ul>
                {sortedPlayers.map(player => (
                    <li key={player.name}>
                        {player.name}: {player.points}
                    </li>
                ))}
            </ul>
            <h2>Winner{winners.length > 1 ? 's' : ''}:</h2>
            <ul>
                {winners.map(winner => (
                    <li key={winner.id}>{winner.name}</li>
                ))}
            </ul>
            <Link to="/">Home</Link>
        </div>
    );
};

export default GameFinished;
