import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NewGame, { GameOptions } from '../Components/NewGame';
import { useApi } from '../Providers/ApiProvider';
import { GameData } from '../Types/core/GameData';

const Home: React.FC = () => {
    const [games, setGames] = useState<GameData[]>([]);
    const [isCreatingGame, setIsCreatingGame] = useState<boolean>(false);
    const {fetch} = useApi();
    useEffect(() => {
        const fetchGames = async () => {
            try {
                const data = await fetch<GameData[]>('games');
                setGames(data);
            } catch (error) {
                console.error('Error fetching games:', error);
            }
        };

        fetchGames();
    }, [fetch]);

    const handleCreateGame = async () => {
        setIsCreatingGame(true);
    };

    const handleOnCreateGame = async (options: GameOptions) => {
        
        setIsCreatingGame(false);
        
        try {
            // Make API call to create a new game
            const newGame = await fetch<GameData>('games', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(options),
            });
            setGames([...games, newGame]);
        } catch (error) {
            console.error('Error creating game:', error);
        }
    }

    const handleOnCancel = () => {
        setIsCreatingGame(false);
    }

    return (
        <div>
            {!isCreatingGame && (<>
            <h1>Games</h1>
            <ul>
                {games.map((game) => (
                    <li key={game.id}><Link to={`/game/${game.id}`} >{game.id}</Link></li>
                ))}
            </ul>
            <button onClick={handleCreateGame}>Create New Game</button>
            </>)}
            {isCreatingGame && (<div>
                <NewGame onStart={handleOnCreateGame} onCancel={handleOnCancel} />
                </div>)}
        </div>
    );
};

export default Home;
