import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import { GamePage } from './Pages/GamePage';
import Layout from './Components/Layout';
import { ApiProvider } from './Providers/ApiProvider';

function App() {
  return (
    <BrowserRouter>
      <ApiProvider>
        <Routes>
            <Route element={<Layout />}>
                <Route path="/" element={<Home />} />
                <Route path="/game/:gameId" element={<GamePage />} />
            </Route>
        </Routes>
      </ApiProvider>
    </BrowserRouter>
  );
}

export default App;
